<template>
  <div v-if="subsidyCreditors.length > 0">
    <v-col cols="12">
      <h2 v-html="$t('subsidy_creditor_title')"></h2>
      <div v-for="(subsidyCreditor, i) in subsidyCreditors" :key="i">
        <v-row loading="loading">
          <v-col sm="12" md="6" xl="6">
            <v-text-field id="subsidy_creditor_iban" readonly v-model="subsidyCreditor.iban" :label="$t('subsidy_creditor_iban')" />
          </v-col>
          <v-col sm="12" md="6" xl="6">
            <v-text-field id="subsidy_creditor_creditor" readonly v-model="subsidyCreditor.kreditor" :label="$t('subsidy_creditor_creditor')" />
          </v-col>
          <v-col sm="12" md="6" xl="6" v-if="subsidyCreditor.bank && subsidyCreditor.bank.name">
            <v-text-field id="subsidy_creditor_bank_name" readonly v-model="subsidyCreditor.bank.name" :label="$t('subsidy_creditor_bank_name')" />
          </v-col>
          <v-col sm="12" md="6" xl="6">
            <v-text-field id="subsidy_creditor_date" readonly :value="$formatChDateTime(subsidyCreditor.modifierDate)" :label="$t('subsidy_creditor_date')" />
          </v-col>
          <v-col sm="12" md="6" xl="6">
            <v-text-field id="subsidy_creditor_user" readonly v-model="subsidyCreditor.modifier" :label="$t('subsidy_creditor_user')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="6" xl="6">
            <v-textarea filled auto-grow rows="3" readonly :value="createTextAreaValue(subsidyCreditor)" />
          </v-col>
          <v-col sm="12" md="6" xl="6">
            <v-btn color="secondary" @click="toggleHistoryDialog(subsidyCreditor)">
              <v-icon dbmblueprimary left class="mdi-history">mdi-history</v-icon>
              <span v-html="$t('subsidy_creditor_history_button')"> </span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-dialog max-width="1200px" v-model="historyDialog" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5" v-html="$t('subsidy_creditor_history_dialog_title')"></span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col sm="12" md="6" xl="6">
              <h2 v-html="$t('subsidy_creditor_history_dialog_current_data')"></h2>
              <div v-for="(subsidyCreditor, i) in subsidyCreditors" :key="i">
                <span v-html="$t('subsidy_creditor_history_dialog_iban')" />: {{ iban4Blocks(subsidyCreditor.iban) }}<br />
                <span v-html="$t('subsidy_creditor_history_dialog_creditor_number')" />: {{ subsidyCreditor.kreditor }}<br />
                <span v-html="$t('subsidy_creditor_history_dialog_user')" />: {{ subsidyCreditor.name }}<br />
                <span v-html="$t('subsidy_creditor_history_dialog_address')" />: {{ createTextAreaValue(subsidyCreditor) }}<br />
              </div>
            </v-col>
          </v-row>
          <v-row loading="loadingHistory" v-if="subsidyCreditorsHistory">
            <v-data-table-server
              :headers="fields"
              :items="subsidyCreditorsHistory"
              :fields="fields"
              :loading="loading"
              :items-per-page-options="$itemsPerPageOptions"
            >
              <template v-slot:[`item.datetime`]="{ item }">
                <div>{{ $formatChDateTime(item.datetime) }}</div>
              </template>
            </v-data-table-server>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn size="sm" color="primary" @click="toggleHistoryDialog()">
            <span v-html="$t('subsidy_creditor_history_dialog_close')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL } from '@/main'
import { DTSHeaders } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'displaySubsidyCreditor',
  props: {
    id: { type: [Number, String], default: '' }, // this.$route.params.id is a string
    backendApi: { type: String, default: '' },
    creditorType: { type: String, default: 'production' } // production || processing
  },
  data() {
    return {
      subsidyCreditors: [
        {
          iban: undefined,
          kreditor: undefined,
          bank: {
            name: undefined
          }
        }
      ],
      subsidyCreditorsHistory: [
        {
          iban: undefined,
          kreditor: undefined,
          bank: {
            name: undefined
          }
        }
      ],
      loading: false,
      loadingHistory: false,
      absolute: true,
      overlay: false,
      historyDialog: false,
      fields: <DTSHeaders[]>[
        {
          title: this.$t('subsidy_creditor_history_dialog_iban'),
          key: 'iban',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('subsidy_creditor_history_dialog_creditor_number'),
          key: 'sapNo',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('subsidy_creditor_history_dialog_date'),
          key: 'datetime',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('subsidy_creditor_history_dialog_user'),
          key: 'username',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('subsidy_creditor_history_dialog_address'),
          key: 'address',
          sortable: false,
          align: 'left'
        }
      ]
    }
  },
  methods: {
    async toggleHistoryDialog(subsidyCreditor) {
      if (!this.historyDialog) {
        await this.loadSubsidyCreditorHistory(subsidyCreditor)
        this.historyDialog = !this.historyDialog
        return
      }
      this.historyDialog = !this.historyDialog
    },
    bankName(creditor) {
      return this.creditor.bank.name
    },
    createTextAreaValue(subsidyCreditor) {
      return `${subsidyCreditor.name ? subsidyCreditor.name + '\n' : ''}${subsidyCreditor.zusatz ? subsidyCreditor.zusatz + '\n' : ''}${
        subsidyCreditor.strasse && subsidyCreditor.hausnummer ? subsidyCreditor.strasse + ' ' + subsidyCreditor.hausnummer + '\n' : ''
      }${subsidyCreditor.plz && subsidyCreditor.ort ? subsidyCreditor.plz + ' ' + subsidyCreditor.ort + '\n' : ''}`
    },
    async loadSubsidyCreditor() {
      try {
        this.loading = true
        const result = await this.axios.get(`${apiURL}/${this.backendApi}/${this.id}/subsidyCreditors`, {
          headers: { Accept: 'application/json' }
        })
        if (await result) {
          if (this.creditorType == 'processing') {
            //processing muss eine localunit haben
            return result.data.filter((element) => typeof element.localUnitId == 'number')
          } else {
            //production darf keine localunit haben
            return result.data.filter((element) => typeof element.localUnitId != 'number') // null types as object
          }
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async loadSubsidyCreditorHistory(subsidyCreditor) {
      try {
        this.loadingHistory = true
        const result = await this.axios.get(`${apiURL}/subsidyCreditors/${subsidyCreditor.id}/history`, {
          headers: { Accept: 'application/json' }
        })
        if (await result) {
          this.subsidyCreditorsHistory = result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingHistory = false
      }
    },
    iban4Blocks(val) {
      if (val) {
        return val
          .replace(/[^\dA-Z]/g, '')
          .replace(/(.{4})/g, '$1 ')
          .trim()
      } else return
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.subsidyCreditors = await this.loadSubsidyCreditor()
      //this.subsidyCreditorsHistory = await this.loadSubsidyCreditorHistory()
    })
  }
})
</script>

<style scoped>
.mdi-history {
  margin-top: 3px;
}
</style>
